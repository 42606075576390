import { useState } from "react";
import { MenuItem, Menu, IconButton } from "@mui/material";
import { useTranslate } from "react-admin";
import { BulkActionsPopup } from "./BulkActionsPopup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";

export const BulkActions = (props) => {
    const [menu, setMenu] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectMenuOption = (e) => {
        setMenu(e.target.dataset.menuitem);
        handleClose();
    };

    const translate = useTranslate();

    return (
        <Box>
            {menu && (
                <BulkActionsPopup {...props} menu={menu} setMenu={setMenu} />
            )}

            <IconButton
                id="bulk-action-button"
                aria-controls={open ? "bulk-action-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                title={translate("equip.bulkActions")}
            >
                <MoreVertIcon />
            </IconButton>
            {props.checkedItems?.length > 0 ? (
                <Menu
                    id="bulk-action-menu"
                    MenuListProps={{
                        "aria-labelledby": "bulk-action-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        title={translate("equip.editName")}
                        aria-label={translate("equip.editName")}
                        data-menuitem={"editName"}
                        onClick={selectMenuOption}
                    >
                        {translate("equip.editName")}
                    </MenuItem>
                    <MenuItem
                        title={translate("equip.editDescription")}
                        aria-label={translate("equip.editDescription")}
                        data-menuitem={"editDescription"}
                        onClick={selectMenuOption}
                    >
                        {translate("equip.editDescription")}
                    </MenuItem>
                    <MenuItem
                        title={translate("equip.editPrice")}
                        aria-label={translate("equip.editPrice")}
                        data-menuitem={"editPrice"}
                        onClick={selectMenuOption}
                    >
                        {translate("equip.editPrice")}
                    </MenuItem>
                    <MenuItem
                        title={translate("equip.addTags")}
                        aria-label={translate("equip.addTags")}
                        data-menuitem={"addTags"}
                        onClick={selectMenuOption}
                    >
                        {translate("equip.addTags")}
                    </MenuItem>
                </Menu>
            ) : (
                <Menu
                    id="bulk-action-menu"
                    MenuListProps={{
                        "aria-labelledby": "bulk-action-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        onClick={handleClose}
                        title={translate("equip.noProductsSelected")}
                        aria-label={translate("equip.noProductsSelected")}
                        data-menuitem={translate("equip.noProductsSelected")}
                    >
                        {translate("equip.noProductsSelected")}
                    </MenuItem>
                </Menu>
            )}
        </Box>
    );
};
