import { useState, useEffect, useCallback } from "react";
import {
    TableCell,
    TableRow,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
} from "@mui/material";
import { useDataProvider, useTranslate, useNotify } from "react-admin";
import debounce from "debounce";
import { BulkActions } from "./BulkActions";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { MuiAutocomplete } from "../../components/inputs/Mui/MuiAutocomplete";

export const CategoryListFilter = ({ filter, ...props }) => {
    const [referenceFilters, setReferenceFilters] = useState({
        tags: "",
        suppliers: "",
        manufacturers: "",
    });
    const [choices, setChoices] = useState({
        tags: [],
        suppliers: [],
        manufacturers: [],
    });
    const dataProvider = useDataProvider();
    const notify = useNotify();

    let getReferencesTimer;

    const getReferenceFilter = useCallback(
        (input, id, resource) => {
            const filterParams = id
                ? {
                      id: id,
                  }
                : {
                      name: input?.target.value,
                  };
            dataProvider
                .getList(resource, {
                    record: null,
                    filter: filterParams,
                    pagination: {
                        page: 1,
                        perPage: 10,
                    },
                    sort: {
                        field: "id",
                        order: "ASC",
                    },
                })
                .then((response) => {
                    setReferenceFilters((prev) => {
                        return {
                            ...prev,
                            [resource]: input?.target.value
                                ? input.target.value
                                : response?.data[0]?.name,
                        };
                    });

                    setChoices((prev) => ({
                        ...prev,
                        [resource]: response.data.map((el) => {
                            return { label: el.name, id: el.id };
                        }),
                    }));
                })
                .catch((e) => {
                    handleHttpError(e, notify);
                });
            getReferencesTimer.clear();
        },
        [dataProvider, getReferencesTimer, notify]
    );

    getReferencesTimer = debounce(getReferenceFilter, 500);

    useEffect(() => {
        const referencedResources = {
            filterName: ["tag", "supplier", "manufacturer"],
            listName: ["tags", "suppliers", "manufacturers"],
        };

        referencedResources.filterName.forEach((item, index) => {
            if (filter?.[item]) {
                getReferenceFilter(null, filter[item], referencedResources.listName[index]);
            } else {
                setReferenceFilters((prev) => {
                    return {
                        ...prev,
                        [referencedResources.listName[index]]: "",
                    };
                });
            }
        });
    }, [filter, getReferenceFilter]);

    const translate = useTranslate();

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell sx={{ width: "20px", position: "relative" }}>
                        <BulkActions {...props} />
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <TextField
                            label={translate("equip.name")}
                            title={translate("equip.name")}
                            variant="outlined"
                            defaultValue={filter.name ? filter.name : ""}
                            size="small"
                            onChange={(e) =>
                                props.handleFilterChange({
                                    ...filter,
                                    name: e.target.value,
                                })
                            }
                        />
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <TextField
                            label={translate("equip.description")}
                            title={translate("equip.description")}
                            variant="outlined"
                            defaultValue={filter.description ? filter.description : ""}
                            size="small"
                            onChange={(e) =>
                                props.handleFilterChange({
                                    ...filter,
                                    description: e.target.value,
                                })
                            }
                        />
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <TextField
                            label={translate("equip.labelNumber")}
                            title={translate("equip.labelNumber")}
                            variant="outlined"
                            defaultValue={
                                filter.labelNumberWithSku ? filter.labelNumberWithSku : ""
                            }
                            size="small"
                            onChange={(e) =>
                                props.handleFilterChange({
                                    ...filter,
                                    labelNumberWithSku: e.target.value,
                                })
                            }
                        />
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <TextField
                            label={translate("equip.edispo.id")}
                            title={translate("equip.edispo.id")}
                            variant="outlined"
                            defaultValue={filter.edispoId ? filter.edispoId : ""}
                            size="small"
                            onChange={(e) =>
                                props.handleFilterChange({
                                    ...filter,
                                    edispoId: e.target.value,
                                })
                            }
                        />
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <FormControl size="small">
                            <InputLabel>{translate("equip.type")}</InputLabel>
                            <Select
                                value={filter?.isMassProduct ? filter.isMassProduct : ""}
                                label={translate("equip.type")}
                                title={translate("equip.type")}
                                onChange={(e) =>
                                    props.handleFilterChange({
                                        ...filter,
                                        isMassProduct: e.target.value,
                                    })
                                }
                            >
                                <MenuItem
                                    value=""
                                    title={translate("equip.none")}
                                    aria-label={translate("equip.none")}
                                >
                                    <em>{translate("equip.none")}</em>
                                </MenuItem>
                                <MenuItem
                                    value={"0"}
                                    title={translate("equip.individualSkus")}
                                    aria-label={translate("equip.individualSkus")}
                                >
                                    {translate("equip.individualSkus")}
                                </MenuItem>
                                <MenuItem
                                    value={"1"}
                                    title={translate("equip.massProduct")}
                                    aria-label={translate("equip.massProduct")}
                                >
                                    {translate("equip.massProduct")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <MuiAutocomplete
                            sx={{ minWidth: 120 }}
                            disablePortal
                            options={choices.tags}
                            size="small"
                            value={referenceFilters.tags}
                            label={translate("equip.tags")}
                            onChange={(e, value) =>
                                props.handleFilterChange({
                                    ...filter,
                                    tag: value?.id ? value?.id : "",
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(input, id) => getReferenceFilter(input, id, "tags")}
                                    label={translate("equip.tags")}
                                />
                            )}
                        />
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <MuiAutocomplete
                            sx={{ minWidth: 120 }}
                            disablePortal
                            options={choices.suppliers}
                            size="small"
                            value={referenceFilters.suppliers}
                            label={translate("equip.supplier")}
                            onChange={(e, value) =>
                                props.handleFilterChange({
                                    ...filter,
                                    supplier: value?.id ? value?.id : "",
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(input, id) =>
                                        getReferenceFilter(input, id, "suppliers")
                                    }
                                    label={translate("equip.supplier")}
                                />
                            )}
                        />
                    </TableCell>

                    <TableCell sx={{ minWidth: 120 }}>
                        <MuiAutocomplete
                            sx={{ minWidth: 120 }}
                            disablePortal
                            options={choices.manufacturers}
                            size="small"
                            value={referenceFilters.manufacturers || null}
                            label={translate("equip.manufacturer")}
                            onChange={(e, value) => {
                                props.handleFilterChange({
                                    ...filter,
                                    manufacturer: value?.id ? value?.id : "",
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(input, id) =>
                                        getReferenceFilter(input, id, "manufacturers")
                                    }
                                    label={translate("equip.manufacturer")}
                                />
                            )}
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
