module.exports = {
    de: {
        equip: {
            language: "Sprache",
            language_german: "deutsch",
            language_english: "englisch",
            name: "Name",
            description: "Beschreibung",
            isMassProduct: "ist Mengenartikel",
            isContainer: "ist Container",
            quantity: "Anzahl",
            openQuantity: "Offene Menge",
            lostQuantity: "Verloren",
            defectQuantity: "Defekt",
            delayedQuantity: "Verspätet",
            status: "Status",
            availability: "Verfügbarkeit",
            inStock: "Lagermenge",
            actualQuantity: "verfügbare Menge",
            rentals: "Ausleihen",
            category: "Kategorie",
            categories: "Kategorien",
            manufacturer: "Hersteller",
            manufacturers: "Hersteller",
            supplier: "Lieferant",
            suppliers: "Lieferanten",
            storageLocation: "Lagerort",
            storageLocations: "Lagerorte",
            users: "Benutzer",
            product: "Produkt",
            products: "Produkte",
            type: "Typ",
            tags: "Tags",
            originCountry: "Ursprungsland",
            deliveryDate: "Lieferdatum",
            purchasePrice: "Einkaufspreis (EUR)",
            purchasePricePerItem: "Einkaufspreis pro Stück (EUR)",
            currentPrice: "Zeitwert (EUR)",
            carnetPrice: "Carnet Preis",
            weight: "Gewicht (kg)",
            weightPerItem: "Gewicht pro Stück (kg)",
            sku: "SKU",
            skus: "SKUs",
            labelNumber: "Label-Nummer",
            sku_status_active: "aktiv",
            sku_status_deactivated: "deaktiviert",
            sku_status_eliminated: "ausgesondert",
            sku_availability_available: "verfügbar",
            sku_availability_rented_out: "verliehen",
            sku_availability_defect: "defekt",
            sku_availability_lost: "verlust",
            hint_add2rental: "Soll dieser Artikel auf eine bereits bestehende Ausleihe gebucht werden? Ist dies der Fall, bitte die gewünschte Ausleihe öffnen und den Artikel darin scannen.",
            inventory: "Bestand",
            firstname: "Vorname",
            lastname: "Nachname",
            email: "E-Mail",
            userIdentifier: "Benutzername",
            password: "Passwort",
            all: "Alle",
            viewAll: "Alle anzeigen",
            addMainCategory: "Hauptkategorie hinzufügen",
            addSubCategory: "Subkategorie hinzufügen",
            createCategoryUnder: 'Kategorie unter "%{name}" erstellen',
            confirmDelete: "Der Datensatz kann nicht wiederhergestellt werden. Fortfahren?",
            state: "Status",
            startDate: "Start",
            endDate: "Ende",
            signature: "Unterschrift",
            signatureDate: "Unterschriftsdatum",
            downloadPdf: "PDF herunterladen",
            downloadCarnet: "Carnet Excel herunterladen",
            downloadEliminationPdf: "Aussonderung PDF herunterladen",
            sendEmail: "Per E-Mail versenden",
            emailSent: "E-Mail wurde versendet",
            lost: "Verlust",
            returned: "Zurückgegeben",
            defect: "Defekt",
            delayed: "Verspätet",
            comment: "Kommentar",
            returnComment: "Kommentar (Rückgabe)",
            lostComment: "Kommentar (Verlust)",
            updateItem: "Eintrag aktualisieren",
            created: "Erstellt",
            size: "Größe",
            media: "Medien",
            arrivalHistory: "Wareneingänge",
            title: "Titel",
            insertLink: "Bitte einen Link einfügen",
            allowedFileTypes: "Erlaubte Dateitypen: PDF, PNG, JPG, JPEG, GIF",
            orderDate: "Bestelldatum",
            orderNumber: "Bestellnummer",
            purchaseOrderNumber: "Auftragsnummer",
            deliveredOk: "Geliefert OK",
            deliveredDefect: "Geliefert Defekt",
            editArrival: "Wareneingang bearbeiten",
            newDelivery: "Neuer Wareneingang",
            save: "Speichern",
            delete: "Löschen",
            close: "Schließen",
            edit: "Bearbeiten",
            viewArrival: "Wareneingänge einsehen",
            arrival: "Wareneingang",
            editSkus: "SKUs bearbeiten",
            editSku: "SKU bearbeiten",
            addSkus: "SKU hinzufügen",
            createSku: "SKU erstellen",
            saveAndContinue: "Speichern & weiter",
            show: "Anzeigen",
            rentedOut: "Verliehen",
            editPrice: "Carnet Preis bearbeiten",
            editName: "Name bearbeiten",
            addTags: "Tags hinzufügen",
            noProductsSelected: "Keine Produkte ausgewählt",
            massProduct: "Mengenartikel",
            individualSkus: "Einzelne SKUs",
            none: "Keine",
            addMedia: "Medien hinzufügen",
            uploadMedia: "Medien hochladen",
            linkToExternalResource: "Link zur externen Ressource",
            addText: "Text hinzufügen",
            download: "Herunterladen",
            createProduct: "Produkt erstellen",
            validateDeliveryDate: "Lieferdatum darf nicht vor dem Bestelldatum liegen",
            validateOrderDate: "Bestelldatum darf nicht nach dem Lieferdatum liegen",
            preview: "Vorschau",
            errorLabel: "Fehler",
            error: "Ein unbekannter Fehler ist aufgetreten",
            error_400: "Der Server kann die Anfrage nicht bearbeiten (400)",
            error_404: "Inhalt nicht gefunden (404)",
            error_500: "Serverinterner Fehler (500)",
            error_checksum: "Prüfsumme nicht korrekt",
            error_skuAvailability: "SKU ist nicht verfügbar",
            error_skuAlreadyAdded: "SKU wurde schon hinzugefügt",
            roles: "Rollen",
            permissionsError: "Fehler: unzureichende Berechtigung",
            permissionsErrorDetails: "Unzureichende Berechtigung um die Ressource anzuzeigen oder mit ihr zu interagieren",
            editDescription: "Beschreibung bearbeiten",
            changeLogs: "Änderungsprotokoll",
            rentalLogs: "Ausleihprotokoll",
            from: "Von",
            to: "Bis",
            changed_to: "Zu",
            field: "Feld",
            otherOptions: "Andere Optionen",
            assignEdispoInfo: "eDISPO-Informationen zuweisen",
            assignEdispoManually: "Manuell zuweisen",
            assignEdispoFromRentalSlip: "Aus Leihschein zuordnen",
            edispoId: "eDISPO Id",
            edispoType: "eDISPO Typ",
            edispoIdSiblings: "eDISPO Produkt Verknüpfungen",
            edispoIdAssign: "eDISPO Id zuweisen",
            confirmEdispoAssignment: "%{edispoName} (%{edispoId}) wirklich diesem Element zuordnen?",
            confirmEdispoIdOverwrite: "Das Produkt hat bereits eine eDISPO Id. Überschreiben?",
            unassignEdispoInfoConfirmation: "Zuordnung von eDISPO-Informationen zu diesem Produkt wirklich aufheben?",
            confirmDeleteEdispoItem: "Buchungen wirklich in eDISPO entfernen?",
            resource: "Ressource",
            date: "Datum",
            writeOffYears: "Abschreibungsjahre",
            eliminateSku: "Eine SKU aussondern",
            eliminate: "Aussondern",
            sale: "Verkauf",
            scrapping: "Verschrottung",
            loss: "Verlust",
            conversionOfFixedAssets: "Umsetzung von Gegenständen des Anlagevermögens",
            product_info: "Informationen zum Produkt",
            user: "Benutzer",
            tag: "Tag",
            rental: "Ausleihe",
            proceed: "Weiter",
            reason: "Grund",
            eliminationType_sale: "Verkauf",
            eliminationType_scrapping: "Verschrottung",
            eliminationType_loss: "Verlust",
            eliminationType_conversionOfFixedAssets: "Umsetzung von Gegenständen des Anlagevermögens",
            eliminations: "Aussonderungen",
            elimination: "Aussonderung",
            serialNumber: "Seriennummer",
            print: "Drucken",
            printLabels: "Etiketten drucken",
            labelQuantity: "Anzahl der Labels pro Einheit",
            error_empty_supplierId: "Das Feld -Lieferant- darf nicht leer sein",
            back: "zurück",
            unordered: "Nicht zugeordnet",
            deleteImage: "Bild löschen",
            allowedImageTypes: "Erlaubte Dateitypen: PNG, JPG, JPEG, GIF",
            productImage: "Produktbild",
            categoryToolbar: "Kategorie-Tools",
            labelPrintJobPlaced: "Druckauftrag abgesendet.",
            labelPrintJobNotPlaced: "Druckauftrag konnte nicht abgesendet werden.",
            search: "Suche",
            allOpenRentals: "Alle offenen Ausleihen",
            searchPerson: "Person suchen",
            searchProducts: "Produkte suchen",
            searchRentals: "Ausleihen suchen",
            renter: "Entleiher",
            scan: "Scannen",
            persons: "Personen",
            person: "Person",
            viewSkuList: "SKU-Liste anzeigen",
            available: "verfügbar",
            rent: "Ausleihen",
            of: "von",
            personDetails: "Angaben zur Person",
            skuList: "SKU-Liste",
            article: "Artikel",
            changeStatus: "Status ändern",
            notAvailable: "n.v.",
            globalSearchLabel: "Label-Nummer / Ausleihnr. / Benutzername",
            userSearchLabel: "Name / PLZ / Stadt / Straße",
            productSearchLabel: "Name / SKU-Nummer",
            rentalSearchLabel: "Ausleihnr.",
            editRentalDates: "Daten der Ausleihe bearbeiten",
            cancel: "Abbrechen",
            changeTheReturnDate: "Rückgabedatum ändern",
            renterDetails: "Entleiher*in",
            changeRenter: "Entleiher*in wechseln",
            confirm: "Bestätigen",
            confirmRenterChange: "Entleiher*in-Wechsel bestätigen",
            rentalItemManagement: "Ausleihe",
            items: "Artikel",
            item: "Artikel",
            addItems: "Artikel hinzufügen",
            return: "Rückgabe",
            printCarnet: "Carnet drucken",
            deleteRental: "Ausleihe löschen",
            rentalDetails: "Ausleihe",
            rentalId: "Ausleihe-Id",
            rentalDate: "Datum der Ausleihe",
            returnDate: "Datum der Rückgabe",
            delayReturnDate: "Verspätung",
            plannedReturnDate: "Ausleihe bis",
            finish: "Abschließen",
            newRental: "Neue Leihe",
            rentalDateExplanation: "Folgende Daten sind voreingestellt und können später für jeden Artikel geändert werden:",
            createRental: "Neue Leihe",
            open: "Offen",
            closed: "Finalisiert",
            noRenter: "Kein Ausleiher",
            rental_inprogress: "Ausleihe in Arbeit",
            add_rental_process: "Neuen Ausleihvorgang erstellen",
            remove: "Entfernen",
            removeAll: "Alle entfernen",
            for: "für",
            add: "Hinzufügen",
            add_to_rental: "Leihen",
            deleteRentalItem: "Artikel wirklich löschen?",
            deleteItem: "Artikel wirklich löschen?",
            showDetails: "Details anzeigen",
            hideDetails: "Details ausblenden",
            missing_signature: "Bitte Unterschrift tätigen",
            opened_rental: "Erfolgreich eröffnete Ausleihe",
            home: "Startseite",
            noResultsFound: "Keine Ergebnisse gefunden für \"%{searchString}\"",
            rentalList: "Liste der Ausleihen",
            return_inprogress: "Rückgabe im Gange",
            partially_returned: "Teilweise zurückgegeben",
            returnManagement: "Rückgabe-Management",
            rentedAmount: "Verliehene Anzahl",
            addSkuLabel: "SKU- / Produktnummer",
            enterQuantity: "Menge eingeben für",
            onlyMine: "Nur eigene",
            removeSwitch: "Zurückbuchen",
            receivable: "Nur offene",
            continueReturn: "Rückgabe fortsetzen",
            rentalItem: "Artikel",
            productDetails: "Einzelheiten zum Produkt",
            reportDelayed: "Verspätung",
            reportLost: "Verlustmeldung",
            delayedUntil: "Aufgeschoben bis",
            author: "Autor",
            comments: "Kommentare",
            addComments: "Kommentare hinzufügen",
            unincludedItemMessage: "Eingegebene Artikelnummer nicht Teil dieser Ausleihe. Bitte wählen:",
            unincludedItemStartRental: "Neue Ausleihe mit diesem Artikel starten",
            unincludedItemGoToRental: "Aktive Ausleihe mit diesem Artikel ansehen",
            unincludedItemListRentals: "Zeige alle aktiven Ausleihen mit diesem Artikel",
            unincludedItemGoToItem: "Diesen Artikel ansehen",
            rentalItems_returned: "Keine ausstehenden Artikel mehr vorhanden. Ausleihe abschließen oder noch Änderungen an den Artikeln vornehmen?",
            makeChanges: "Änderungen",
            close_session: "Abschließen",
            close_rental: "Finalisieren",
            close_rental_text: 'Artikel der Ausleihe, die nicht als "zurückgenommen", "verloren" oder "defekt" markiert sind, werden als "verloren" gekennzeichnet. Fortfahren?',
            close_rental_success: "Ausleihe finalisiert",
            sendReceipt: "Leihbeleg an weitere Empfänger*innen schicken",
            downloadReceipt: "Leihbeleg speichern",
            receiptBanner: "Ausleihe erfolgreich eröffnet. Der Leihbeleg wurde an den/die Entleiher*in versandt.",
            additionalRecipients: "Zusätzliche Empfänger*innen",
            send: "Senden",
            addRecipient: "Empfänger*in hinzufügen",
            addVdeCheck: "VDE-Prüfung hinzufügen",
            day: "Tag",
            days: "Tage",
            daysOverdue: "Verzug",
            createRentalOnClosedDay: "Das Startdatum für die Leihe liegt auf einem Schließtag. Fortfahren?",
            outdatedRentalReturnDate: "Ein oder mehrere Artikel der Ausleihe weisen ein Rückgabedatum in der Vergangenheit auf. Bitte ändern bevor die Leihe abgeschlossen wird.",
            signature_text: "Der Ausleih-Gegenstand wurde mir, dem/der Entleiher*in %{firstname} %{lastname}, in einwandfreiem Zustand übergeben. Von der Technik-Leihordnung der Filmakademie Baden-Württemberg habe ich Kenntnis genommen. Ich versichere, dass ich grundsätzlich qualifiziert bin und die geliehene Technik bestimmungsgemäß einsetze. Eine Weitergabe oder Übergabe an Dritte ist nicht möglich.",
            changeReturnDateForAllItems: "Rückgabedatum für alle Artikel ändern",
            addToClipboard: "Zur Zwischenablage hinzufügen",
            removeFromClipboard: "Aus der Zwischenablage entfernen",
            clipboard: "Zwischenablage",
            clearClipboard: "Zwischenablage leeren",
            emptyClipboard: "Die Zwischenablage ist leer.",
            confirmClearClipboard: "Zwischenablage wirklich leeren?",
            action: "Aktion",
            reference: "Referenz",
            productNotfound: "Kein Produkt gefunden",
            rentalsTimeline: "Zeitleiste für Ausleihen",
            rentalsTimeline_noData: "Keine Daten für den angegebenen Zeitraum verfügbar",
            period: "Zeitraum",
            week: "Woche",
            twoWeek: "Zwei Wochen",
            month: "Monat",
            today: "Heute",
            custom: "Benutzerdefiniert",
            compareWithActualData: "mit aktuellen Daten vergleichen",
            timeframeWarning_start_date_after_end_date: "Das Startdatum wird nach dem Enddatum festgelegt",
            timeframeWarning_timeframe_too_wide: "Der gewählte Zeitrahmen erstreckt sich über 60 Tage",
            compareWithOldData: "mit alten Daten vergleichen",
            confirmRemoveItem: "Möchten Sie diesen Artikel wirklich entfernen?",
            changeRentalName: "Name ändern",
            bulkActions: "Massenaktionen",
            createdBy: "Erstellt von",
            create: "Erstellen",
            export: "Exportieren",
            content: "Inhalt",
            forward: "Weiterleiten",
            productCategoryTree: "Produktkategorie Baum",
            active: "Aktiv",
            invitation_pending: "Einladung ausstehend",
            deactivated: "Deaktiviert",
            noEdispoProduct: "Kein eDISPO Produkt",
            fulfilled: "Erfüllt",
            fulfillmentInProgress: "Erfüllung in Arbeit",
            dateDifferences: "Datumsunterschiede",
            scannedTooMany: "Zu viele gescannt",
            missingItem: "Fehlender Artikel",
            notInEdispoList: "Nicht in der eDispo-Liste",
            darkTheme: "Dunkel", 
            lightTheme: "Hell",
            confirmRentalMerge: "Möchten Sie Ton und Kamera zusammenführen oder trennen?",
            merge: "Zusammenführen",
            split: "Trennen",
            dunning: {
                label: "Verzug/Mahnstatus",
                level: 'Mahnstufe',
                level_0: '(ohne)',
                level_1: '1. Mahnung',
                level_2: '2. Mahnung',
                level_3: '3. Mahnung',
                send_level_1: '1. Mahnung schicken',
                send_level_2: '2. Mahnung schicken',
                send_level_3: '3. Mahnung schicken',
                send_dunning_email_text: 'Wollen Sie die Mahnung wirklich verschicken?'
            },
            edispo: {
                id: "eDISPO Id",
                type: "eDISPO Typ",
                in_1a: "Kamera",
                in_1b: "Ton",
                renter_check: "Ist der/die Ausleiher*in vor Dir %{firstname} %{lastname}?",
                unfinishedEdispoScan: "Artikel des eDISPO-Leihscheins vorhanden, die noch nicht gescannt wurden. Ausleihe ohne diese abschließen?",
                createBookings: "eDISPO-Buchungen anlegen"
            },
            rental_log: {
                rental_inprogress: "Ausleihe erstellt",
                return_inprogress: "Rückkehr im Gange",
                open: "Unterschrieben",
                closed: "Finalisiert",
                returned: "Ausleihe vollständig zurückgegeben",
                reminder_email: "Erinnerungsmail versendet",
                dunning_level_1: "Erste Mahnung versendet",
                dunning_level_2: "Zweite Mahnung versendet",
                dunning_level_3: "Dritte Mahnung versendet",
                dunning_level_3_warning: "Verwarnungs-E-Mail zur dritten Mahnung versendet",
                rental_slip_generation: "Leihschein wurde generiert",
                carnet_generation: "Carnet Datei wurde generiert",
                delay_report: "Verspätung gemeldet",
                loss_report: "Verlust gemeldet",
                return_item: "Rückgabe",
                add_rental_process: "Neuer Ausleihvorgang erstellt",
                cancel_rental_process: "Neuer Ausleihvorgang abgebrochen"
            },
        },
        error: {
            product: {
                empty_name: "Das Feld Name muss für alle Sprachen ausgefüllt werden.",
                empty_description: "Das Feld Beschreibung muss für alle Sprachen ausgefüllt werden.",
                empty_carnetPrice: "Bitte geben sie ein Carnet Preis an.",
                empty_tags: "Bitte geben Sie mindestens ein Tag an.",
                delete_not_possible: "Ein Löschen des Artikels ist nicht möglich.",
            },
            elimination: {
                quantity_mismatch: "Aussonderung von mehr Menge als vorhanden nicht möglich.",
                no_mass_product: "Die Produktaussonderung ist nur für Massenprodukte verfügbar. Bitte stattdessen die SKU-Aussonderung verwenden.",
                sku_not_deactivated: "Nur deaktivierte SKU können ausgesondert werden.",
                sku_already_eliminated: "SKU ist bereits ausgesondert.",
            },
            rental: {
                closed_weekday: "An diesem Tag ist die Rückgabe nicht möglich. Bitte einen anderen Wochentag wählen.",
                no_item_found: "Für die angegebene Etikettennummer wurde kein Artikel gefunden.",
                item_already_exists: "Artikel mit SKU existiert bereits.",
                item_already_returned: "Der Artikel wurde bereits zurückgegeben.",
                sku_not_available: "Der gewählte Artikel wird bereits anderweitig verwendet.",
                sku_not_active: "SKU ist nicht aktiv.",
                negative_quantity: "Negative Menge erreicht.",
                invalid_state_transition: "Ungültiger Zustandsübergang.",
                exceeded_max_quantity: "Die Höchstmenge darf nicht überschritten werden.",
                exceeded_max_rentedQuantity: "Der Wert kann die zur Verfügung stehende Menge nicht überschreiten.",
                min_quantity: "Die Menge muss größer als 0 sein.",
                min_quantity_0: "Die Mindestmenge beträgt 0.",
                rental_dates: "Das Rückgabedatum kann nicht vor dem Leihdatum liegen.",
                start_date_too_early: 'Die Leihgegenstände können erst am %{startDate} ausgegeben werden.',
                empty_rental: 'Die Ausleihe ist leer.',
                rental_process_not_empty: 'Der Ausleihvorgang ist nicht leer.',
                renter_is_missing: 'Ein/e Ausleiher*in muss angegeben werden.',
            },
            edispo: {
                resource_not_available: "Eine bestehende eDISPO-Reservierung verhindert das Hinzufügen dieses Produkts (mit der gewünschten Menge).",
                invalid_dates: "Der gescannte eDISPO-Leihschein ist veraltet (Start- oder Enddaten sind nicht mehr gültig).",
                unavailable_rental_items: "Eine bestehende eDISPO-Reservierung verhindert das Ausleihen folgender Produkte: %{itemName}",
                rental_item_not_found: "Die gewünschte eDISPO Id konnte nicht gefunden werden.",
                create_dispo: "eDISPO-Eintrag kann nicht erzeugt werden.",
                validate_rental: "%{resourceType} / %{resourceId}: %{message}",
                validate_rental_quanitity_exceeded: "%{resourceType} / %{resourceId}: Menge überschritten",
                validate_rental_end_date_exceeded: "%{resourceType} / %{resourceId}: Zeitraum überschritten",
                validate_rental_item_not_found: "%{resourceType} / %{resourceId}: Artikel wurde nicht gebucht",
            },
            printer: {
                socket_create: "Socket kann nicht erstellt werden.",
            },
            tag: {
                already_exists: "Ein Tag mit diesem Namen existiert bereits.",
            },
            sku: {
                delete_not_possible: "Ein Löschen des Artikels ist nicht möglich.",
            },
        },
    },
    en: {
        equip: {
            language: "Language",
            language_german: "german",
            language_english: "english",
            name: "Name",
            description: "Description",
            isMassProduct: "is Mass Product",
            isContainer: "is Container",
            quantity: "Quantity",
            openQuantity: "Open Quantity",
            lostQuantity: "Lost",
            defectQuantity: "Defect",
            delayedQuantity: "Delayed",
            status: "Status",
            availability: "Availability",
            inStock: "In Stock",
            actualQuantity: "Available Quantity",
            rentals: "Rentals",
            category: "Category",
            categories: "Categories",
            manufacturer: "Manufacturer",
            manufacturers: "Manufacturers",
            supplier: "Supplier",
            suppliers: "Suppliers",
            storageLocation: "Storage Location",
            storageLocations: "Storage Locations",
            users: "Users",
            product: "Product",
            products: "Products",
            type: "Type",
            tags: "Tags",
            originCountry: "Origin Country",
            deliveryDate: "Delivery Date",
            purchasePrice: "Purchase Price (EUR)",
            purchasePricePerItem: "Purchase Price per Item (EUR)",
            currentPrice: "Current Price (EUR)",
            carnetPrice: "Carnet Price",
            weight: "Weight (kg)",
            weightPerItem: "Weight per Item (kg)",
            sku: "SKU",
            labelNumber: "Label-Number",
            skus: "SKUs",
            sku_status_active: "active",
            sku_status_deactivated: "deactivated",
            sku_status_eliminated: "eliminated",
            sku_availability_available: "available",
            sku_availability_rented_out: "rented out",
            sku_availability_defect: "defect",
            sku_availability_lost: "lost",
            hint_add2rental: "Should this item be added to an existing rental? If this is the case, please open the desired rental and scan the item there.",
            inventory: "Inventory",
            firstname: "Firstname",
            lastname: "Lastname",
            email: "Email",
            userIdentifier: "Username",
            password: "Password",
            all: "All",
            viewAll: "View All",
            addMainCategory: "Add Main Category",
            addSubCategory: "Add Sub Category",
            createCategoryUnder: 'Create Category under "%{name}"',
            confirmDelete: "Data can not be restored. Proceed?",
            state: "State",
            startDate: "Start",
            endDate: "End",
            signature: "Signature",
            signatureDate: "Signature Date",
            downloadPdf: "Download PDF",
            downloadCarnet: "Download Carnet Excel",
            downloadEliminationPdf: "Download Elimination PDF",
            sendEmail: "Send per E-Mail",
            emailSent: "E-Mail sent",
            lost: "Lost",
            returned: "Returned",
            defect: "Defect",
            delayed: "Delayed",
            comment: "Comment",
            returnComment: "Return Commentent",
            lostComment: "Lost Comment",
            updateItem: "Update Item",
            created: "Created",
            size: "Size",
            media: "Media",
            arrivalHistory: "Arrival History",
            title: "Title",
            insertLink: "Please insert a link",
            allowedFileTypes: "Allowed file types: PDF, PNG, JPG, JPEG, GIF",
            orderDate: "Order Date",
            orderNumber: "Order Number",
            purchaseOrderNumber: "Purchase Order Number",
            deliveredOk: "Delivered OK",
            deliveredDefect: "Delivered Defect",
            editArrival: "Edit arrival",
            newDelivery: "New Delivery",
            save: "Save",
            delete: "Delete",
            close: "Close",
            edit: "Edit",
            viewArrival: "View Arrival",
            arrival: "Arrival",
            editSkus: "Edit SKUs",
            editSku: "Edit SKU",
            addSkus: "Add SKUs",
            createSku: "Create SKU",
            saveAndContinue: "Save & Continue",
            show: "Show",
            rentedOut: "Rented Out",
            editPrice: "Edit carnet price",
            editName: "Edit name",
            addTags: "Add tags",
            noProductsSelected: "No products were selected",
            none: "None",
            individualSkus: "Individual SKUs",
            massProduct: "Mass Product",
            addMedia: "Add Media",
            uploadMedia: "Upload media",
            linkToExternalResource: "Link to external resource",
            addText: "Add text",
            download: "Download",
            createProduct: "Create product",
            validateDeliveryDate: "Delivery date cannot be ealier than the order date",
            validateOrderDate: "Order date cannot be later than the delivery date",
            preview: "Preview",
            errorLabel: "Error",
            error: "An unknown error has occurred",
            error_400: "Bad Request Error (400)",
            error_404: "Not Found Error (404)",
            error_500: "Internal Server Error (500)",
            error_checksum: "Wrong checksum",
            error_skuAvailability: "SKU is not available",
            error_skuAlreadyAdded: "SKU already added",
            roles: "Roles",
            permissionsError: "Error: insufficient authorization",
            permissionsErrorDetails: "Insufficient authorization to view or interact with the resource",
            editDescription: "Edit description",
            changeLogs: "Change logs",
            rentalLogs: "Rental logs",
            from: "From",
            to: "To",
            changed_to: "Changed to",
            field: "Field",
            otherOptions: "Other options",
            assignEdispoInfo: "Assign eDISPO information",
            assignEdispoManually: "Assign manually",
            assignEdispoFromRentalSlip: "Assign from rental slip",
            edispoId: "eDISPO Id",
            edispoType: "eDISPO Type",
            edispoIdSiblings: "eDISPO product links",
            edispoIdAssign: "Assign eDISPO Id",
            confirmEdispoAssignment: "Should %{edispoName} (%{edispoId}) really be assigned to this item?",
            confirmEdispoIdOverwrite: "The product has an eDISPO Id already. Overwrite?",
            unassignEdispoInfoConfirmation: "Do you really want to remove the assignment of eDISPO information to this item?",
            confirmDeleteEdispoItem: "Do you really want to delete this item in eDISPO?",
            resource: "Resource",
            date: "Date",
            writeOffYears: "Write-off Years",
            eliminateSku: "Eliminate a SKU",
            eliminate: "Eliminate",
            sale: "Sale",
            scrapping: "Scrapping",
            loss: "Loss",
            conversionOfFixedAssets: "Conversion of fixed assets",
            product_info: "Product information",
            user: "User",
            tag: "Tag",
            rental: "Rental",
            proceed: "Proceed",
            reason: "Reason",
            eliminationType_sale: "Sale",
            eliminationType_scrapping: "Scrapping",
            eliminationType_loss: "Loss",
            eliminationType_conversionOfFixedAssets: "Conversion of fixed assets",
            elimination: "Elimination",
            eliminations: "Eliminations",
            serialNumber: "Serial Number",
            printLabels: "Print labels",
            print: "Print",
            labelQuantity: "Number of labels per item",
            error_empty_supplierId: "Supplier field cannot be empty",
            back: "Back",
            unordered: "Unordered",
            deleteImage: "Delete Image",
            allowedImageTypes: "Allowed file types: PNG, JPG, JPEG, GIF",
            productImage: "Product image",
            categoryToolbar: "Category Toolbar",
            labelPrintJobPlaced: "Print job sent.",
            labelPrintJobNotPlaced: "Print job could not be sent.",
            search: "Search",
            allOpenRentals: "All Open Rentals",
            searchPerson: "Search Person",
            searchProducts: "Search Products",
            searchRentals: "Search Rentals",
            renter: "Renter",
            scan: "Scan",
            persons: "Persons",
            person: "Person",
            viewSkuList: "View SKU List",
            available: "available",
            rent: "rent",
            of: "of",
            personDetails: "Person details",
            skuList: "SKU List",
            article: "Article",
            changeStatus: "Change status",
            notAvailable: "N/A",
            globalSearchLabel: "Label-Number / Rental-Id / Username",
            userSearchLabel: "Name / ZIP / City / Street",
            productSearchLabel: "Name / SKU number",
            rentalSearchLabel: "Rental-Id",
            editRentalDates: "Edit rental dates",
            cancel: "Cancel",
            changeTheReturnDate: "Change return date",
            renterDetails: "Renter details",
            changeRenter: "Change renter",
            confirm: "Confirm",
            confirmRenterChange: "Confirm renter change",
            rentalItemManagement: "Rental item management",
            items: "Items",
            item: "Item",
            addItems: "Add items",
            return: "Return",
            printCarnet: "Print carnet",
            deleteRental: "Delete rental",
            rentalDetails: "Rental details",
            rentalId: "Rental-Id",
            rentalDate: "Rental date",
            returnDate: "Return date",
            delayReturnDate: "Delay",
            plannedReturnDate: "Planned return date",
            finish: "Finish",
            newRental: "New rental",
            rentalDateExplanation: "The following dates are presets and can be changed later for every item:",
            createRental: "Create rental",
            open: "Open",
            closed: "Finalized",
            noRenter: "No renter",
            rental_inprogress: "Rental in progress",
            add_rental_process: "Add rental process",
            remove: "Remove",
            removeAll: "Remove all",
            for: "for",
            add: "Add",
            add_to_rental: "Add",
            deleteRentalItem: "Really delete this rental item?",
            deleteItem: "Really delete this item?",
            showDetails: "Show details",
            hideDetails: "Hide details",
            missing_signature: "Please add a signature",
            opened_rental: "Successfully opened Rental",
            home: "Home",
            noResultsFound: "No results for \"%{searchString}\"",
            rentalList: "Rental list",
            return_inprogress: "Return in progress",
            partially_returned: "Partially returned",
            returnManagement: "Return management",
            rentedAmount: "Rented amount",
            addSkuLabel: "SKU / Product number",
            enterQuantity: "Enter quantity for",
            onlyMine: "Only mine",
            removeSwitch: "Remove items",
            receivable: "Receivable",
            continueReturn: "Continue return",
            rentalItem: "Rental item",
            productDetails: "Product details",
            reportDelayed: "Report delayed",
            reportLost: "Report lost",
            delayedUntil: "Delayed until",
            author: "Author",
            comments: "Comments",
            addComments: "Add comments",
            unincludedItemMessage: "Item number entered not part of this rental. Please choose:",
            unincludedItemStartRental: "Start a new rental with this article",
            unincludedItemGoToRental: "Go to the rental with this article",
            unincludedItemListRentals: "List all active rentals with this article",
            unincludedItemGoToItem: "View this article",
            rentalItems_returned: "No articles remaining to be returned for this rental. Close the return session now or still make changes to the articles?",
            makeChanges: "Make changes",
            close_session: "Close session",
            close_rental: "Finalize",
            close_rental_text: 'Items of this rental that are not set to "returned", "lost" or "defective" will be marked as “lost”. Continue?',
            close_rental_success: "Rental finalized",
            sendReceipt: "Send rental receipt to additional recipients",
            downloadReceipt: "Download rental receipt",
            receiptBanner: "Rental opened successfully. The receipt has been sent to the renter.",
            additionalRecipients: "Additional recipients",
            send: "Send",
            addRecipient: "Add a recipient",
            addVdeCheck: "Add VDE Check",
            day: "day",
            days: "days",
            daysOverdue: "Delay",
            createRentalOnClosedDay: "Rental start date is on a closing day. Proceed?",
            outdatedRentalReturnDate: "One or more of the rental items have a return date in the past. Please change this before finishing this rental.",
            signature_text: 'The rental items were handed over to me, renter %{firstname} %{lastname}, in perfect condition. I have taken note of the rental agreement of the Filmakademie Baden-Württemberg. I assure that I am fundamentally qualified and will use the rented items as intended. A forwarding or transfer to third parties is not possible.',
            changeReturnDateForAllItems: "Change return date for all items",
            addToClipboard: "Add to clipboard",
            removeFromClipboard: "Remove from clipboard",
            clipboard: "Clipboard",
            clearClipboard: "Clear clipboard",
            emptyClipboard: "The clipboard is empty.",
            confirmClearClipboard: "Really clear the clipboard?",
            action: "Action",
            reference: "Reference",
            productNotfound: "No product was found",
            rentalsTimeline: "Rentals timeline",
            rentalsTimeline_noData: "No data available for the give period",
            period: "Period",
            week: "Week",
            twoWeek: "Two week",
            month: "Month",
            today: "Today",
            custom: "Custom",
            compareWithActualData: "Compare with actual data",
            timeframeWarning: "Time frame warning",
            timeframeWarning_start_date_after_end_date: "The start date is set after the end date",
            timeframeWarning_timeframe_too_wide: "The selected timeframe spans over 60 days",
            compareWithOldData: "Compare with old data",
            confirmRemoveItem: "Do you really want to remove this item?",
            changeRentalName: "Change rental name",
            bulkActions: "Bulk actions",
            createdBy: "Created by",
            create: "Create",
            export: "Export",
            content: "Content",
            forward: "Forward",
            productCategoryTree: "Product category tree",
            active: "Active",
            invitation_pending: "Invitation pending",
            deactivated: "Deactivated",
            noEdispoAssigned: "No eDISPO product",
            fulfilled: "Fulfilled",
            fulfillmentInProgress: "Fulfillment in progress",
            dateDifferences: "Date differences",
            scannedTooMany: "Scanned too many",
            missingItem: "Missing item",
            notInEdispoList: "Not in eDispo list",
            darkTheme: "Dark theme",
            lightTheme: "Light theme",
            confirmRentalMerge: "Do you want to merge or split sound/camera?",
            merge: "Merge",
            split: "Split",
            dunning: {
                label: "Delay/Dunning status",
                level: 'Dunning Level',
                level_0: '(none)',
                level_1: '1. Reminder',
                level_2: '2. Reminder',
                level_3: '3. Reminder',
                send_level_1: 'Send 1. Reminder',
                send_level_2: 'Send 2. Reminder',
                send_level_3: 'Send 3. Reminder',
                send_dunning_email_text: 'Do you really want to send the reminder?'
            },
            edispo: {
                id: "eDISPO Id",
                type: "eDISPO Type",
                in_1a: "Camera",
                in_1b: "Sound",
                renter_check: "Is the renter in front of you %{firstname} %{lastname}?",
                unfinishedEdispoScan: "There are still open items on the eDISPO rental slip that have not been added yet. Finish the rental without them?",
                createBookings: "Create eDISPO-Bookings"
            },
            rental_log: {
                rental_inprogress: "Rental generated",
                return_inprogress: "Items returned",
                open: "Rental signed",
                closed: "Return finalized",
                returned: "Rental fully returned",
                reminder_email: "Reminder E-Mail sent",
                dunning_level_1: "1st Dunning Letter sent",
                dunning_level_2: "2nd Dunning Letter sent",
                dunning_level_3: "3rd Dunning Letter sent",
                dunning_level_3_warning: "3rd Dunning warning sent",
                rental_slip_generation: "Rental slip generated",
                carnet_generation: "Carnet generated",
                delay_report: "Late return created",
                loss_report: "Marked as lost",
                return_item: "Return",
                add_rental_process: "Added rental process",
                cancel_rental_process: "Canceled new rental process"
            },
        },
        error: {
            product: {
                empty_name: "Name field must be filled for all locales.",
                empty_description: "Description field must be filled for all locales.",
                empty_carnetPrice: "Please enter a valid carnet price.",
                empty_tags: "Please add at least one tag.",
                delete_not_possible: "It is not possible to delete this item.",
            },
            elimination: {
                quantity_mismatch: "Elimination of more quantity than in existence not possible.",
                no_mass_product: "Product Elimination only available for Mass Products. Use SKU elimination instead.",
                sku_not_deactivated: "Only deactivated SKU can be eliminated.",
                sku_already_eliminated: "SKU is already eliminated.",
            },
            rental: {
                closed_weekday: "Returns are not possible on this day. Please select a different day of the week.",
                no_item_found: "No item found for given label number.",
                item_already_exists: "Item with sku already exists.",
                item_already_returned: "Item is already returned.",
                sku_not_available: "The selected item is already being used elsewhere.",
                sku_not_active: "SKU is not active.",
                negative_quantity: "Negative quantity reached.",
                invalid_state_transition: "Invalid state transition.",
                exceeded_max_quantity: "Cannot exceed maximum quantity.",
                exceeded_max_rentedQuantity: "The value cannot exceed rented quantity.",
                min_quantity: "Quantity must be greater than 0.",
                min_quantity_0: "Minimum quantity is 0.",
                rental_dates: "Return date cannot be earlier than rental date.",
                start_date_too_early: 'The rental items cannot be issued until %{startDate}.',
                empty_rental: 'The rental is empty.',
                rental_process_not_empty: 'The rental process is not empty.',
                renter_is_missing: 'The renter is missing.',
            },
            edispo: {
                resource_not_available: "An existing eDISPO reservation prevents to add this product (with the selected amount).",
                invalid_dates: "The eDISPO rental slip you have scanned is outdated (either start date or end date is not valid any longer).",
                unavailable_rental_items: "An existing eDISPO reservation prevents the rental of following products: %{itemName}",
                rental_item_not_found: "The requested eDISPO Id could not be found.",
                create_dispo: "Cannot create eDISPO entry.",
                validate_rental: "%{resourceType} / %{resourceId}: %{message}",
                validate_rental_quanitity_exceeded: "%{resourceType} / %{resourceId}: Quantity exceeded",
                validate_rental_end_date_exceeded: "%{resourceType} / %{resourceId}: Timeframe exceeded",
                validate_rental_item_not_found: "%{resourceType} / %{resourceId}: Article not booked",
            },
            printer: {
                socket_create: "Cannot create socket.",
            },
            tag: {
                already_exists: "A tag with this name already exists.",
            },
            sku: {
                delete_not_possible: "It is not possible to delete this item.",
            },
        },
    },
};
