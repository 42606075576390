import { Box, Button, Dialog, DialogTitle, TextField } from "@mui/material";
import { useLocaleState, usePermissions, useTranslate } from "react-admin";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const EditReturnDateDialog = ({ returnDate, setReturnDate, open, onClose, onSubmit }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const [locale] = useLocaleState();
    const url = window.location.href;
    const device = url.includes("mobile") || url.includes("3001") ? "mobile" : "desktop";

    return (
        <ConfirmationDialog
            label={translate("equip.changeReturnDateForAllItems")}
            open={open}
            onCancel={onClose}
            onConfirm={onSubmit}
            buttonSize="large"
            content={
                <Box>
                    <LocalizationProvider
                        adapterLocale={locale}
                        dateAdapter={AdapterDayjs}
                        localeText={{
                            cancelButtonLabel: translate("equip.cancel"),
                            okButtonLabel: "Ok",
                        }}
                    >
                        {device === "mobile" ? (
                            <MobileDatePicker
                                minDate={dayjs()}
                                label={translate("equip.returnDate")}
                                value={returnDate}
                                inputFormat="DD.MM.YYYY"
                                shouldDisableDate={(e) =>
                                    (permissions.config.get("closedWeekdays") || []).includes(
                                        e.day()
                                    )
                                }
                                onChange={(newDate) => {
                                    setReturnDate(newDate);
                                }}
                                slotProps={{ textField: { variant: "outlined" } }}
                            />
                        ) : (
                            <DesktopDatePicker
                                minDate={dayjs()}
                                label={translate("equip.returnDate")}
                                value={returnDate}
                                format="DD.MM.YYYY"
                                shouldDisableDate={(e) =>
                                    (permissions.config.get("closedWeekdays") || []).includes(
                                        e.day()
                                    )
                                }
                                onChange={(newDate) => {
                                    setReturnDate(newDate);
                                }}
                            />
                        )}
                    </LocalizationProvider>
                </Box>
            }
        />
    );
};
