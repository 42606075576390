export function handleHttpError (error, notify)
{
    let errorMessage = error.body?.error;
    let messageArgs = [];
    if (errorMessage) {
        try {
            const errorMessages = JSON.parse(errorMessage);
            messageArgs = errorMessages[0].parameters;
            errorMessage = errorMessages[0].message;
        } catch (e) {}
    }

    if (error.status === 400) {
        notify(errorMessage || "equip.error_400", { type: "error", messageArgs });
    } else if (error.status === 404) {
        notify(errorMessage || "equip.error_404", { type: "error", messageArgs });
    } else if (error.status === 500) {
        notify(errorMessage || "equip.error_500", { type: "error", messageArgs });
    } else {
        notify(errorMessage || "equip.error", { type: "error", messageArgs });
    }
}
