import { Autocomplete, Box } from "@mui/material";

export const MuiAutocomplete = ({ label, ...props }) => {
    return (
        <Autocomplete
            title={label}
            label={label}
            renderOption={(props, option) => (
                <Box {...props} key={option.id} title={option.label}>
                    {option.label}
                </Box>
            )}
            {...props}
        />
    );
};
