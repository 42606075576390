import { Box, Button, Card, DialogContent } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
    Form,
    TextInput,
    useRefresh,
    NumberInput,
    Toolbar,
    ReferenceArrayInput,
    useCreate,
    useTranslate,
    useUpdateMany,
    TranslatableInputs,
    useUpdate,
    useNotify,
} from "react-admin";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { RaAutocompleteArrayInput } from "../../components/inputs/Ra/RaAutocompleteArrayInput";

export const BulkActionsPopup = (props) => {
    const [updateMany] = useUpdateMany();
    const [update] = useUpdate();
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();

    const validate = (data) => {
        const errors = {};
        switch (props.menu) {
            case "editName":
                Object.keys(data.name).forEach((key) => {
                    if (!data.name[key]) {
                        errors.name = errors.name || {};
                        errors.name[key] = translate(
                            "error.product.empty_name"
                        );
                        return;
                    }
                });
                break;
            case "editDescription":
                Object.keys(data.description).forEach((key) => {
                    if (!data.description[key]) {
                        errors.description = errors.description || {};
                        errors.description[key] = translate(
                            "error.product.empty_description"
                        );
                        return;
                    }
                });
                break;
            case "editPrice":
                if (!data.carnetPrice) {
                    const errors = {};
                    errors.carnetPrice = translate(
                        "error.product.empty_carnetPrice"
                    );
                    return errors;
                }
                break;
            case "addTags":
                if (0 === data.tags.length) {
                    const errors = {};
                    errors.tags = translate("error.product.empty_tags");
                    return errors;
                }
                break;
            default:
                break;
        }

        return errors;
    };

    const submit = ({ name, description, carnetPrice, tags }) => {
        let data = {
            ...(props.menu === "editName" && { name }),
            ...(props.menu === "editDescription" && { description }),
            ...(props.menu === "editPrice" && { carnetPrice }),
            ...(props.menu === "addTags" && { tags }),
        };

        if (props.menu === "addTags") {
            props.checkedItems.forEach((checkedItem, index) => {
                const tags = [
                    ...data.tags,
                    ...checkedItem.tags.filter(
                        (tag) => !data.tags.includes(tag)
                    ),
                ];
                console.log(tags);
                update(
                    "products",
                    {
                        id: checkedItem.id,
                        data: { tags },
                    },
                    {
                        onSettled: () => {
                            if (index === props.checkedItems.length - 1) {
                                props.setCheckedItems([]);
                                props.setMenu();
                                refresh();
                            }
                        },
                        onError: (e) => {
                            handleHttpError(e, notify);
                        },
                    }
                );
            });
        } else {
            updateMany(
                "products",
                {
                    ids: props.checkedItems.map((el) => el.id),
                    data,
                },
                {
                    onSettled: () => {
                        props.setCheckedItems([]);
                        props.setMenu();
                        refresh();
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        }
    };

    return (
        <Box
            sx={{
                position: "absolute",
                top: "110px",
                zIndex: 3,
                left: 25,
                right: 0,
                margin: "auto",
                width: "465px",
                maxWidth: "100vw",
            }}
        >
            <Card elevation={10} sx={{ paddingBottom: "10px" }}>
                <Form
                    validate={validate}
                    onSubmit={submit}
                    record={
                        props.checkedItems.length === 1 && props.checkedItems[0]
                    }
                >
                    <Box sx={{ marginTop: "1em", padding: "2em" }}>
                        {props.menu === "editName" && <EditName {...props} />}
                        {props.menu === "editDescription" && (
                            <EditDescription {...props} />
                        )}
                        {props.menu === "editPrice" && <EditPrice {...props} />}
                        {props.menu === "addTags" && <AddTags {...props} />}
                    </Box>
                    <Toolbar
                        sx={{
                            justifyContent: "space-between",
                            background: "inherit",
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon />}
                            title={translate("equip.save")}
                        >
                            {translate("equip.save")}
                        </Button>
                        <Button
                            title={translate("equip.close")}
                            size="medium"
                            onClick={() => props.setMenu()}
                        >
                            {translate("equip.close")}
                        </Button>
                    </Toolbar>
                </Form>
            </Card>
        </Box>
    );
};

const EditPrice = () => {
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.editPrice")}
            </DialogContent>
            <NumberInput
                label="equip.carnetPrice"
                title={translate("equip.carnetPrice")}
                source="carnetPrice"
                fullWidth
                options={{ style: "currency", currency: "EUR" }}
                required
            />
        </Box>
    );
};

const EditName = () => {
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.editName")}
            </DialogContent>
            <TranslatableInputs locales={["de", "en"]}>
                <TextInput
                    label="equip.name"
                    title={translate("equip.name")}
                    source="name"
                    fullWidth
                />
            </TranslatableInputs>
        </Box>
    );
};

const EditDescription = (props) => {
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.editDescription")}
            </DialogContent>
            <TranslatableInputs locales={["de", "en"]}>
                <TextInput
                    label="equip.description"
                    title={translate("equip.description")}
                    source="description"
                    //required
                    fullWidth
                    multiline
                    rows={3}
                />
            </TranslatableInputs>
        </Box>
    );
};

const AddTags = () => {
    const [create] = useCreate();
    const translate = useTranslate();
    return (
        <Box>
            <DialogContent sx={{ paddingLeft: 0, fontSize: 16 }}>
                {translate("equip.addTags")}
            </DialogContent>

            <ReferenceArrayInput
                source="tags"
                reference="tags"
                label="equip.tags"
                sort={{ field: "name", order: "ASC" }}
                fullWidth
            >
                <RaAutocompleteArrayInput
                    onCreate={(value) => {
                        return create(
                            "tags",
                            { data: { name: value } },
                            { returnPromise: true }
                        ).then((response) => {
                            return response;
                        });
                    }}
                    label={translate("equip.tags")}
                    field="name"
                    multiple={true}
                />
            </ReferenceArrayInput>
        </Box>
    );
};
