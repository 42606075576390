import {
    useTranslate,
    DateTimeInput,
    Form,
    TextInput,
    useRecordContext,
    useNotify,
    useRefresh,
    useResourceContext,
    NumberInput,
} from "react-admin";
import { Button, Box } from "@mui/material";
import { queryToRedirectBack } from "shared/src/utils/decodeQuery";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { ConfirmationDialog } from "shared/src/components/ConfirmationDialog";
import { RaSelectInput } from './inputs/Ra/RaSelectInput';


export const EliminateForm = ({ setEliminate }) => {
    const translate = useTranslate();
    const record = useRecordContext();
    const resource = useResourceContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const eliminate = (input) => {
        const date = new Date(input.date).toLocaleString("sv-SE");

        fetch(`/${resource}/${record.id}/eliminate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                date: date,
                ...input,
            }),
        })
            .then((response) => {
                setEliminate(false);
                refresh();
            })
            .catch((e) => {
                handleHttpError(e, notify);
            });
    };

    return (
        <ConfirmationDialog
            confirmButton={false}
            cancelButton={false}
            label={translate("equip.eliminateSku")}
            content={
                <Form
                    onSubmit={record?.id && eliminate}
                    record={{ date: Date.now() }}
                >
                    <DateTimeInput
                        fullWidth
                        label="equip.date"
                        title={translate("equip.date")}
                        source="date"
                        disabled
                    />
                    <RaSelectInput
                        fullWidth
                        source="type"
                        choices={[
                            { id: "sale", name: "equip.sale" },
                            { id: "scrapping", name: "equip.scrapping" },
                            { id: "loss", name: "equip.loss" },
                            {
                                id: "conversionOfFixedAssets",
                                name: "equip.conversionOfFixedAssets",
                            },
                        ]}
                        required
                    />
                    {resource === "products" && (
                        <NumberInput
                            fullWidth
                            label="equip.quantity"
                            title={translate("equip.quantity")}
                            source="quantity"
                            defaultValue={1}
                            required
                            sx={{ display: "block" }}
                            max={record.quantity}
                            min={1}
                        />
                    )}
                    <TextInput
                        fullWidth
                        label="equip.reason"
                        title={translate("equip.reason")}
                        source="reason"
                        multiline
                        rows={3}
                        required
                    />
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="error"
                            title={translate("equip.proceed")}
                            aria-label={translate("equip.proceed")}
                        >
                            {translate("equip.proceed")}
                        </Button>
                        <Button
                            onClick={() => setEliminate(false)}
                            title={translate("equip.close")}
                            aria-label={translate("equip.close")}
                        >
                            {translate("equip.close")}
                        </Button>
                    </Box>
                </Form>
            }
        />
    );
};
